// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const getUserCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url':`${config.baseUrl}/adminapi/routesAdmin/usercount`,
        });
        console.log(respData);
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getCollectionCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/routesAdmin/collectioncount`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            // error: err.response.data.errors
        }
    }
}

export const getMintCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/routesAdmin/mintcount`,
        });
        console.log(respData,'ddddrespData')
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getBidderCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/bidderCount`,
        });
        console.log(respData.data,'dataaaaaaaaaaa')
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}