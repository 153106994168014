let key = {};
if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")
    const API_URL = ' https://7thseaapi.wearedev.team';//DEMO
    
    key = {
        baseUrl: `${API_URL}`,
        singlesmartContract : "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //live
        adminAddress:'0x33Bf605135ea9d568B8b05D96b93Ba1105015138',
        network : '11155111',
        };

} else {
    console.log("Set Development Config")
    const API_URL = 'http://localhost' ;
    key = {
        baseUrl: `${API_URL}:3040`,
        singlesmartContract : "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //demo
        adminAddress:'0x33Bf605135ea9d568B8b05D96b93Ba1105015138',
        network : '11155111' ,
        };
}


export default key;