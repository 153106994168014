import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import 'react-phone-input-2/lib/style.css'
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";


import { getadminDetails, updateProfile, updatepassword } from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none"
  }
};

// toaster config
toast.configure();




const useStyles = makeStyles(styles);
const customStyle = makeStyles(customInputStyle);

export default function ChangePassword() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();


  const [validateError, setValidateError] = useState({});

  //   const [data,setdata]=useState({name:'',email:'',phoneNumber:'',companyname:''})

  const [oldpassword, setoldPassword] = useState('')
  const [newpassword, setnewpassword] = useState('')
  const [conformpassword, setconformpassword] = useState('')
  const [showoldpassword, setShowoldpassword] = useState(true)
  const [shownewpassword, setShownewpassword] = useState(true)
  const [showconformpassword, setShowconformpassword] = useState(true)


  console.log(showoldpassword, "showoldpassword");


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload = {

      oldpassword: oldpassword,
      newpassword: newpassword,
      conformpassword: conformpassword,

    }

    const { status, message, error, result } = await updatepassword(payload);

    if (status) {
      toastAlert('success', message, 'Admin')
      history.push('/doyjyfvewkjd/dashboard')

    }
    else {
      toastAlert('error', message, 'Admin')
      setValidateError(error);
    }
  }
  //   const getadmindata = async () => {
  //     var { result } = await getadminDetails();
  //     if (result) {
  //       setdata(result)
  //       setname(result.name)
  //       setEmail(result.email)
  //       setPhonenumber(result.phoneNumber)
  //       setCompanyname(result.companyname)
  //       // setAdminName(result.name)
  //       // setAdminEmail(result.email)
  //     }
  //   }
  //   console.log(data,'data');
  //   useEffect(() => {

  //     getadmindata();
  //   }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Users</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      onChange={(e) => setoldPassword(e.target.value)}
                      name="name"
                      type={showoldpassword ? 'text' : 'password'}
                      //   value={passow}
                      formControlProps={{
                        fullWidth: true
                      }}

                    />
                    {
                      showoldpassword ?

                        <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '56px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"

                        }} onClick={() => setShowoldpassword(false)} >
                          <i class="fa fa-eye"></i>

                        </button>

                        : <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '56px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"


                        }} onClick={() => setShowoldpassword(true)} >

                          <i class="fa fa-eye-slash"></i> </button>
                    }

                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput variant="filled"

                      labelText="New Password"
                      onChange={(e) => setnewpassword(e.target.value)}
                      type={shownewpassword ? 'text' : 'password'}
                      name='email'
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      shownewpassword ?

                        <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '56px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"

                        }} onClick={() => setShownewpassword(false)} >
                          <i class="fa fa-eye"></i>

                        </button>

                        : <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '56px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"


                        }} onClick={() => setShownewpassword(true)} >

                          <i class="fa fa-eye-slash"></i> </button>

                    }
                   
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Conform Password"
                      onChange={(e) => setconformpassword(e.target.value)}
                      name="name"
                      type={showconformpassword ? 'text' : 'password'}
                      //   value={passow}
                      formControlProps={{
                        fullWidth: true
                      }}

                    />
                    {
                      showconformpassword ?

                        <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '130px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"

                        }} onClick={() => setShowconformpassword(false)} >
                          <i class="fa fa-eye"></i>

                        </button>

                        : <button style={{
                          position: "absolute", fontSize: '25px',
                          top: '130px',
                          marginLeft: '-30px',
                          background: "transparent",
                          border: "none"


                        }} onClick={() => setShowconformpassword(true)} >

                          <i class="fa fa-eye-slash"></i> </button>
                    }

                  </GridItem>

                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
