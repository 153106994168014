import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import * as moment from "moment";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { Button, makeStyles } from "@material-ui/core";
// import {Grid} from "@material-ui";
import { useHistory } from 'react-router-dom'
import { GetAllFAQ, deletFAQ, updateFaq, updateFaqStatus } from "../../actions/admin";

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { toast } from "react-toastify";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
export default function FAQList() {
    const classes = useStyles();
    const ScrollRef=useRef()
  const [data,setdata]=useState([])
    const history = useHistory()
    useEffect(() => {
        getAll();
    }, []);

    const getAll = async () => {
        var res = await GetAllFAQ();
        console.log(res, "res");
        if(res.length){
            res.map((ele,i)=>{
                ele.SSNo=i+1
            })
            setdata(res)
        }
     
    };


    const handlePageChange=(pagenumber)=>{
        if (ScrollRef.current) {
            const tableContainer = ScrollRef.current;
            const tableHeading = tableContainer.querySelector('.table-heading');
          
            console.log(tableHeading);
            if (tableHeading) {
              console.log(pagenumber, 'pageNumber');
              
              // tableContainer.scrollTop = tableHeading.offsetTop;
              tableHeading.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }


    const handleRowPerPageChange=(limit)=>{
    
        if (ScrollRef.current) {
          const tableContainer = ScrollRef.current;
          const tableHeading = tableContainer.querySelector('.table-heading');
         
          console.log(tableHeading);
          if (tableHeading) {
            console.log(limit, 'pageNumber');
            
            // tableContainer.scrollTop = tableHeading.offsetTop;
            tableHeading.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }


   
    const editHandler=async(id)=>{
        console.log(id);
        history.push(`/faq/${id}`)
    }
    const deleteHandler=async(id)=>{
        console.log(id);
        const result = await deletFAQ(id);
          toast.success(result.message)
          setTimeout(() => {
            window.location.reload()

          }, 1500);
    }
    const setFaqStatus=async(value,id)=>{
        console.log(value,id);
        const result=await updateFaqStatus(value,id)
    }
    const columns = [
        {
            name: "S.No.",
            // sortable: true,
            width:'80px',
            height:'350px',
            cell: (row, index) => <div data-tag="allowRowEvents">{row.SSNo}</div>,
        },
        {
            name: "Question",
            // sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents">{row.question}</div>
            ),
        },
        {
            name: "Answer",
            // sortable: true,
            width:"500px",
           height:'500px',
      
            cell: (row) => <div><div  data-tag="allowRowEvents">{row.answers}</div></div>,
        },
        {
            name: "Status",
            // sortable: true,
            
            cell: (row) => (
                
               <BootstrapSwitchButton onlabel="Active" offlabel="InActive" onstyle="success" offstyle="danger"  width={100} checked={row.status==='active'?true:false} onChange={(value)=>setFaqStatus(value,row._id)}/> 
            ),
        },
        {
            name: "Action",
            // sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <EditOutlinedIcon  color="primary" onClick={()=>{editHandler(row._id)}} cursor={'pointer'}/>
                    <DeleteOutlineRoundedIcon color='danger' onClick={()=>{deleteHandler(row._id)}} cursor={'pointer'}/>
                </div>
            ),
        },
    ];
   
console.log(
    data,'dataat'
);

const buttonHandler=async()=>{
    history.push('/faqadd')
}
    return (
        <div>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card>
                        
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>FAQ List</h4>
                        </CardHeader>
                        <CardBody>          
                            <GridItem style={{display:'flex',justifyContent:"end"}}>
                                            <Button  variant={'contained'}   color="primary" onClick={()=>buttonHandler()}>Add Faq</Button>
                                
                                </GridItem>                    
                                      <div ref={ScrollRef} >
                                        <div className="text_pad table-heading">

                                        
                                               {
                                               data&& data.length>0?
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noHeader
                                                    pagination={true}
                                                    onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowPerPageChange}
                                                    paginationPerPage="10"
                                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                />:''
                                            }
                                      </div>
                                      </div>

                               
                     
                          
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        </div>
    );
}
