
import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from './components/Route/ConditionRoute';

// import pages
import LoginPage from './pages/LoginPage/Login';

import Dashboard from './pages/Dashboard/Dashboard';
import '../src/style.css'

import store from './store';

// import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from './style/global.style';
import FAQList from './pages/Faq/faqList'
import AddFAQ from './pages/Faq/faqAdd'
import FAQUpdate from './pages/Faq/faqUpdate'
import UserList from "./pages/user/userList";
import MintList from "./pages/mint/mintlist";
import CollectionList from "./pages/collection/collectionList";
import CreateCollection from "./pages/collection/creatCollection";
// import action 
import { decodeJwt } from './actions/jsonWebToken'
import EditProfile from "./pages/AdminProfile/EditProfile";
import AddMarket from "./pages/market/addmarket";
import Marketlist from "./pages/market/listmarket";
import AddWhiteList from "./pages/whitelist/addwhitelist";
import RemoveWhiteList from "./pages/whitelist/removewhitelist";
import ChangePassword from "./pages/changepassword/changepassword";

const history = createBrowserHistory();

const App = () => {
    const { isAuth } = store.getState().currentUser;

    useEffect(() => {
        if (isAuth != true && localStorage.admin_token) {
            decodeJwt(localStorage.admin_token, store.dispatch)
        }
    }, [])

    return (
        <Provider store={store} >
            <ThemeProvider theme={globalStyle}>
                <ToastContainer />
                {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
                <Router history={history} basename={"/doyjyfvewkjd"}>

                    <Switch>

                        {/* Auth Route */}
                        <ConditionRoute path="/login" component={LoginPage} type={"auth"} />

                        {/* Private Route */}
                        <ConditionRoute path="/dashboard" component={Dashboard} layout={Admin} type={"private"} store={store} />
                        <ConditionRoute path='/faqlist' component={FAQList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/faqadd' component={AddFAQ} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/faq/:id' component={FAQUpdate} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/collectionlist' component={CollectionList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/createcollection' component={CreateCollection} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/userlist' component={UserList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/mintlist' component={MintList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/addmarket/:id' component={AddMarket} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/marketlist/:id' component={Marketlist} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/addWhitelist/:id' component={AddWhiteList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/removewhitelist/:id' component={RemoveWhiteList} layout={Admin} type={'private'} store={store}/>
                        <ConditionRoute path='/changepassword' component={ChangePassword} layout={Admin} type={'private'} store={store}/>
                        {/* Product request */}
                       
                        {/* Ingredient request */}
                        

                        {/* EditProfile */}
                        <ConditionRoute path="/editprofile" component={EditProfile} layout={Admin} type={"private"} />



                        <Redirect from="/" to="/login" />
                    </Switch>
                </Router>
            </ThemeProvider>

        </Provider>
    )

}

export default App;