
import React, { useEffect, useRef, useState } from "react"
import { GetAllUser } from "../../actions/admin"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import { TextField, makeStyles } from "@material-ui/core"
import moment from "moment"
import DataTable from "react-data-table-component"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const UserList = () => {
    const classes = useStyles();
    const [data,setData]=useState([])
    const scrollRef=useRef()
    const [search,setSearch]=useState('')
    useEffect(() => {
        getLoader()
    }, [search])

    const getLoader = async () => {
        const result = await GetAllUser(search)
        console.log(result, 'res');
        if(result.length){
            result.map((ele,i)=>{
                ele.SSNo=i+1
            })
            setData(result)
        }
    }
    const handlePageChange=(pagenumber)=>{
        if (scrollRef.current) {
            const tableContainer = scrollRef.current;
            const tableHeading = tableContainer.querySelector('.table-heading');
          
            console.log(tableHeading);
            if (tableHeading) {
              console.log(pagenumber, 'pageNumber');
              
              // tableContainer.scrollTop = tableHeading.offsetTop;
              tableHeading.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }


    const handleRowPerPageChange=(limit)=>{
    
        if (scrollRef.current) {
          const tableContainer = scrollRef.current;
          const tableHeading = tableContainer.querySelector('.table-heading');
         
          console.log(tableHeading);
          if (tableHeading) {
            console.log(limit, 'pageNumber');
            
            // tableContainer.scrollTop = tableHeading.offsetTop;
            tableHeading.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }

    const columns = [
        {
            name: "S.No.",
            // sortable: true,
            width:'80px',
            height:'350px',
            cell: (row, index) => <div data-tag="allowRowEvents">{row.SSNo}</div>,
        },
        {
            name: "User Address",
            // sortable: true,
            cell: (row) => (
                <div>
                  
                    <div data-tag="allowRowEvents">{row.Address}</div>
                </div>
            ),
        },
    
        {
            name: "Created At",
            // sortable: true,
            
            cell: (row) => (
                
                <div data-tag="allowRowEvents">  {moment(row.timestamp).format("MMMM,Do YYYY, hh:mm A")}</div>
            ),
        },
     
    ];
    const handleChange=(e)=>{
        let {name,value}=e.target
        setSearch(value)
    }
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card >

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>User List</h4>
                        </CardHeader>
                        <CardBody>
                        <GridItem style={{display:'flex',justifyContent:"end"}}>
                                      <TextField type={'Search'} label={"Search"} variant={'outlined'} onChange={(e)=>handleChange(e)} />
                                            </GridItem> 
                                            <div ref={scrollRef}>                                          
                                            <div className="text_pad table-heading" >
                            {
                                               data&& data.length>0?
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    noHeader
                                                    pagination={true}
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handleRowPerPageChange}
                                                    paginationPerPage="10"
                                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                />:''
                                            }
</div>
</div>



                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default UserList