import React from "react"
import Web3 from "web3";
import { getCurAddr } from "./token";
import Network from "../lib/network";
import { toast } from "react-toastify";
import contractAbi from './contractAbi.json'
import { UpdateMarket, createMarket } from "../actions/admin";
const DeleteMarket = async (contractaddress,marketContractAddress,history,setpageLoader) => {

    setpageLoader(true)
    console.log(contractaddress, marketContractAddress);
    try {
        const details = await getCurAddr(Network)
        console.log(details, 'networkdeta');
        if (details == '' || details == null || details == undefined) {
            setpageLoader(false)
            return
        } else {
            console.log('runner');
            if (contractaddress === '') {
                setpageLoader(false)
                return toast.error('Enter the contract address')
            } else if (marketContractAddress == '') {
                setpageLoader(false)
                return toast.error('Enter the market contract address')
            }
            if (window) {
                const { ethereum } = window
                const web3 = new Web3(ethereum || details.details.rpcUrls)
                const contract = new web3.eth.Contract(contractAbi, contractaddress)
                console.log(contract, 'ContractContract');
                const ownerAddress=await contract.methods.owner().call()
                console.log(ownerAddress);
                if(ownerAddress==details.address){

                    const Result = await contract.methods.setWhitelistAddress(marketContractAddress,false).send({ from: details.address })
                    console.log(Result);
                    const payload = {
                        contractaddress: contractaddress,
                        marketcontractaddress: marketContractAddress,
                        status: false,
                        transactionhash: Result.transactionHash
                    }
                    const result = await UpdateMarket(payload)
                
                    history.push('/collectionlist')
                    setpageLoader(false)
                    toast.success(result.message)
                }else {
                    setpageLoader(false)
                 return   toast.error('Your are not owner to update market')
                }
            }
        }
    } catch (error) {
        setpageLoader(false)
        console.log(error);
        return
    }
}

export default DeleteMarket