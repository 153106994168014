// import package
import React, { useEffect, useState } from 'react';

// import material ui
import {
    Store,
    Accessibility
} from "@material-ui/icons";
import { Icon } from "@material-ui/core";

// import componet
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";

// import action
import {  getCollectionCount, getMintCount, getUserCount} from '../../actions/dashboard'



const DashboardCount = (props) => {
    // props
    const { classes } = props;

    // state
    const [countData, setCountData] = useState(0)
    const [mintCount, setMintCount] = useState(0)
    const [collectionCount, setCollectionCount] = useState(0)
    const [bidderCount, setBidderCount] = useState(0)

    // function
    const fetchUserData = async () => {
        const  result  = await getUserCount()
        console.log(result);
            setCountData(result.result.count)
        }
   

    // const fetchTradeCnt = async () => {
    //     const { status, loading, error, result } = await getTradeCount()
    //     if (status == 'success') {
    //         setTradeCount(result.tradeCount)
    //         // setCountData({ ...countData, ...result })
    //     }
    // }


    const fetchCollectionCnt = async () => {
        const {  result } = await getCollectionCount()
       
            setCollectionCount(result.count)
       
    }


    const fetchMintCount = async () => {
        const   result  = await getMintCount()
      console.log(result);
            setMintCount(result.result.count)
          
       
    }


    useEffect(() => {
        fetchUserData()
        // fetchTradeCnt()
        fetchMintCount()
        fetchCollectionCnt()
    }, [])
    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={3}>

                <a href='/userlist' style={{textDecoration:'none'}}> 
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                            <Icon>content_copy</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>User</p>
                        <h3 className={classes.cardTitle}>
                            {countData}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card> 
                </a>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
            <a href='/mintlist' style={{textDecoration:'none'}}> 
                <Card>
                    <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                            <Store />
                        </CardIcon>
                        <p className={classes.cardCategory}>Mint</p>
                        <h3 className={classes.cardTitle}>
                            {mintCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
                </a>
            </GridItem>
            {/* <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                            <Icon>info_outline</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Bidder</p>
                        <h3 className={classes.cardTitle}>
                            {bidderCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
            </GridItem> */}
            <GridItem xs={12} sm={6} md={4}>
            <a href='/collectionlist' style={{textDecoration:'none'}}> 
                <Card>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                            <Accessibility />
                        </CardIcon>
                        <p className={classes.cardCategory}>Collection</p>
                        <h3 className={classes.cardTitle}>
                            {collectionCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
                </a>
            </GridItem>
        </GridContainer>
    )
}


export default DashboardCount;