import React, { useEffect, useRef, useState } from "react"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import { TextField, makeStyles } from "@material-ui/core"
import DataTable from "react-data-table-component"
import { getALlMarket } from "../../actions/admin"
import { useHistory, useParams } from "react-router-dom"
import Button from "../../components/CustomButtons/Button.js";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import DeleteMarket from "../../constant/deleteMarket"
import ReactLoading from 'react-loading'
import './styleL.css'
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const Marketlist = () => {
    const classes = useStyles();
    const params = useParams()
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [PageLoader, setpageLoader] = useState(false)
    const scrollRef=useRef()
    const history = useHistory()
    useEffect(() => {
        getloader()
    }, [search])

    const converter = (addr) => {
        console.log(addr);
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }
    }
    const getloader = async () => {
        console.log(params.id);
        const result = await getALlMarket(params.id, search)
        console.log(result, 'result');
        setData(result)
    }
    const deleteHandler = async (Address) => {
        console.log(Address);
        await DeleteMarket(params.id, Address, history, setpageLoader)
    }
    const handlePageChange=(pagenumber)=>{
        if (scrollRef.current) {
            const tableContainer = scrollRef.current;
            const tableHeading = tableContainer.querySelector('.table-heading');
          
            console.log(tableHeading,'pageNumber');
            if (tableHeading) {
              console.log(pagenumber, 'pageNumber');
              
              // tableContainer.scrollTop = tableHeading.offsetTop;
              tableHeading.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }


    const handleRowPerPageChange=(limit)=>{
    
        if (scrollRef.current) {
          const tableContainer = scrollRef.current;
          const tableHeading = tableContainer.querySelector('.table-heading');
         
          console.log(tableHeading);
          if (tableHeading) {
            console.log(limit, 'pageNumber');
            
            // tableContainer.scrollTop = tableHeading.offsetTop;
            tableHeading.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }

    const columns = [

        {
            name: "S.No.",
            // sortable: true,
            width: '80px',
            height: '350px',
            cell: (row, index) => <div data-tag="allowRowEvents">{index + 1}</div>,
        },
        {
            name: "MarketName",
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.marketname}</div>
                </div>
            ),
        },
        {
            name: 'Website URL',
            // sortable: true,
            width: '150px',
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents"><a href={`${row.websiteURL}`} target="_blank">{row.websiteURL}</a></div>
                </div>
            )
        }, {
            name: 'Contract Address',
            // sortable: true,
            width: '150px',
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{converter(row.contractAddress)}</div>
                </div>
            )
        }, {
            name: 'Market Address',
            // sortable: true,
            width: '150px',
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{converter(row.marketContractAddress)}</div>
                </div>
            )
        }, {
            name: 'Status',
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.status ? <span class="badge badge-pill badge-success">Approved</span> : <span class="badge badge-pill badge-danger">Dis Approved</span>}</div>
                </div>
            )
        }
        , {
            name: 'Action',
            // sortable: true,
            cell: (row) => (
                <div>
                    <DeleteOutlineRoundedIcon color='danger' onClick={() => { deleteHandler(row.marketContractAddress) }} cursor={'pointer'} />
                </div>
            )
        }


    ]
    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
    }

    const buttonHandler = (id) => {
        history.push(`/addmarket/${id}`)
    }
    const buttonHandler1 = () => {
        history.push('/collectionlist')
    }
    return (
        <div>
            <div>
               

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} className="table_grid">
                        <Card>

                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Market List</h4>
                            </CardHeader>
                            <CardBody>
                                <GridItem style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                        <Button color={'primary'} onClick={() => { buttonHandler1() }}>back</Button>


                                        <Button color={'primary'} onClick={() => { buttonHandler(params.id) }}>Add Market</Button>
                                    </GridItem>

                                    <TextField type={'Search'} label={"Search"} variant={'outlined'} onChange={(e) => handleChange(e)} />
                                </GridItem>
                                <div ref={scrollRef} >
                                    <div className="text_pad table-heading">

                                   
                                    {
                                        data && data.length ?
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                pagination={true}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handleRowPerPageChange}
                                                paginationPerPage="10"
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            /> : ''
                                    }
                                     </div>
                                </div>




                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            {
                    PageLoader && <ReactLoading type={"spin"} color={"#000000"} className="loading" />
                }
        </div>
    )
}

export default Marketlist