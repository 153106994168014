import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../lib/config';
import setAuthToken from '../lib/setAuthToken';

import {
  SET_CURRENT_USER
} from '../constant';



//LoginAction

export const login = async (data, dispatch) => {
  console.log(data, dispatch, ':dispatch');

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data,
    });
    if (respData && respData.data && respData.data.token) {
      localStorage.setItem("admin_token", respData.data.token);
      setAuthToken(respData.data.token);
      // decodeJwt(respData.data.token, dispatch);
      return {
        loading: false,
        result: respData.data.result,
      };
    } else {
      return {
        loading: false,
        tfas: respData.data.tfa,
      };
    }

  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};


export const GetAllUser = async (data) => {
  let res = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/user`,
    method: 'get',
    params: { search: data }
  });
  if (res.data.result.length) {
    console.log(res);
    return res.data.result
  }
}
export const GetAllUserwithoutwhitelist = async (data) => {
  let res = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/getusernotwhitelist`,
    method: 'post',
    data: { data }
    // params: { search: data }
  });
  if (res.data.result.length) {
    console.log(res, 'resssssssssssssssssssssss');
    return res.data.result
  }
}
export const GetAllMint = async (data) => {
  let res = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/mint`,
    method: 'get',
    params: { search: data }
  });
  if (res.data.result.length) {
    console.log(res);
    return res.data.result
  }
}

export const GetAllUserwhitelist = async (data) => {
  let res = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/getuserwhitelist`,
    method: 'post',
    data: { data }
    // params: { search: data }
  });
  if (res.data.result.length) {
    console.log(res, 'resssssssssssssssssssssss');
    return res.data.result
  }
}
export const GetAllCollection = async (data) => {
  let res = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/collection/collectionlist`,
    method: 'get',
    params: { search: data }
  })
  if (res.data.result.length) {
    console.log(res, 'collection');
    return res.data.result
  }
}
//get all faq
export const addFaq = async (data) => {
  const result = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/addFaq`,
    method: 'post',
    data: { data }
  })
  console.log(result);
}
export const GetAllFAQ = async () => {
  try {
    const responce = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/getfaq`,
      method: 'get',

    })
    console.log(responce, 'faqlist');
    if (responce.data.result.length) {
      return responce.data.result
    }
  } catch (error) {
    console.log(error);
  }
}
export const updateFaqStatus = async (value, id) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/updatefaqstatus`,
      method: 'post',
      data: { value, id }
    })
    console.log(result.data);
  } catch (error) {
    console.log(error);
  }
}
export const deletFAQ = async (id) => {
  const result = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/deletefaq`,
    method: "post",
    data: { id },
    headers: {
      'Content-Type': 'application/json'
    },
  })
  return result.data
}

//AdminProfile

export const getadminDetails = async () => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `${config.baseUrl}/adminapi/routesAdmin/adminDetails`,
      headers: {
        'Authorization': localStorage.admin_token
      },

    });
    console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.responce,
    }

  }
  catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error
    }
  }
}


export const updatepassword=async(payload)=>{
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/adminapi/routesAdmin/updatepassword`,
      headers: {
        'Authorization': localStorage.admin_token
      },
      data:payload

    });
    console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.responce,
    }

  }
  catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error
    }
  }
}

export const getfaq = async (id) => {
  try {
    const result = await axios(
      {
        url: `${config.baseUrl}/adminapi/routesAdmin/faq/${id}`,
        method: 'get'
      }
    )
    console.log(result.data.data);
    if (result.data.data) {
      return result.data.data
    }
  } catch (error) {
    console.log(error);
  }


}
export const updateFaq = async (data) => {
  console.log(data, 'ddd');
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/updatefaq`,
      method: 'post',
      data: { data }
    })
    return result.data
  } catch (error) {

  }
}

export const SearchCollection = async (data) => {
  console.log(data, "data");

  const responce = await axios({
    url: `${config.baseUrl}/adminapi/routesAdmin/collection/search`,
    method: "get",
    params: { search: data },
  });
  return {
    loading: true,
    result: responce,
  };
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/adminapi/routesAdmin/updateAdminProfile`,
      headers: {
          'Authorization': localStorage.admin_token
      },
      data
    });
       console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error
    }

  }
  catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error
    }
  }
}


export const logout = (history) => {
  localStorage.removeItem('admin_token');
  history.push('/Login')
  setAuthToken('')
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const getContract = async (id) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/getcontractdetails/${id}`,
      method: 'get'
    })
    console.log(result);
    return result.data.res
  } catch (error) {
    console.log();
  }
}

export const createMarket = async (data) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/creatmarket`,
      method: 'post',
      data: data
    })
    return result.data
  } catch (error) {
    console.log(error);
  };
}
export const UpdateMarket = async (data) => {
  console.log(data);
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/updatemarket`,
      method: 'post',
      data: data
    })
    return result.data
  } catch (error) {
    console.log(error);
  };
}

export const getALlMarket = async (id,data) => {
  console.log('runn');
  console.log(id);
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/getmarketlist/${id}`,
      method: 'get',
      params:{search:data}
    
    })

    console.log(result);
    return result.data.result

  } catch (error) {
    console.log(error);
  }
}

export const createWhiteList = async (data) => {
  console.log(data)
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/createwhitelist`,
      method: 'post',
      data: data
    })
    console.log(result);
    return result.data
  } catch (error) {
    console.log(error);
  }
}

export const deleteWhiteList = async (data) => {
  console.log(data)
  try {
    const result = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/deletewhitelist`,
      method: 'post',
      data: data
    })
    console.log(result);
    return result.data
  } catch (error) {
    console.log(error);
  }
}

export const checkMarket=async(id,formvalue)=>{
  const payload={
    id:id,
    formvalue:formvalue
  }
  try {
    const result= await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/checkMarket`,
      method:'post',
      data:payload
    })
    console.log(result)
    return result.data
  } catch (error) {
    console.log(error);
  }

}