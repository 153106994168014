
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getfaq, updateFaq } from "../../actions/admin";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Button } from "react-bootstrap";
import CardFooter from "../../components/Card/CardFooter";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};
const useStyles = makeStyles(styles);
const FAQUpdate = () => {
    const classes = useStyles();
    const history=useHistory()
    const params = useParams()
    const [data, setdata] = useState({question:'',answers:""})
    console.log(params, 'pppppp');
    useEffect(() => {
        getloader()
    }, [])
    const getloader = async () => {
        const result = await getfaq(params.id)
        console.log(result);
        if (result) {

            setdata({...result})
        }
    }
    const handleFormSubmit = async(e) => {
        e.preventDefault()
        if(data.question===''){
            toast.error('Please enter question field')
        }else if(data.answers===''){
            toast.error('Please enter answer field')
        }else{

            const result= await updateFaq(data)
            console.log(result);
            toast.success(result.message)
            history.push('/faqlist')
        }
    }

    const buttonHandler=()=>{
        history.push('/faqlist')
    }
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Update FAQ</h4>
                                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
                            </CardHeader>
                            <CardBody>
                            <GridItem style={{ display: 'flex', justifyContent: "end" }}>
        <Button color={'primary'} onClick={() => { buttonHandler() }}>back</Button>


      </GridItem>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Question"
                                            onChange={(e) => setdata({ ...data, question: e.target.value })}
                                            id="question"
                                            value={data.question}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Answer"
                                            value={data.answers}
                                            onChange={(e) => setdata({ ...data, answers: e.target.value })}
                                            id="answer"
                                            name="answer"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                multiline: true,
                                                rows: 5,
                                            }}
                                        />

                                    </GridItem>

                                </GridContainer>
                                {/* <GridContainer> */}
                                {/* <GridItem xs={12} sm={12} md={3}>
                    Name
                      <CustomInput
                        // labelText="Name"
  
                        id="name"
                        name="name"
                        defaultValue={"name"}
                        inputProps={{
                          value: formData.name,
                          onChange
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      {
                        validateError.name && <span className={classes.textDanger}>{validateError.name}</span>
                      }
                    </GridItem> */}
                                {/* </GridContainer> */}
                                {/*<GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                  <Select
                          onChange={handleChange}
                          options={categorytype}
                          label="select type"
                           formControlProps={{
                          fullWidth: true
                        }}
                        />
                </GridItem>
                      </GridContainer>*/}
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">
                                    Update
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default FAQUpdate