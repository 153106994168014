import React, { useState, useEffect } from "react";
// // @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle";
import Select from "react-select";

import "react-phone-input-2/lib/style.css";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

// import { addFaq, getadminUsers, getcategorytype } from "../../actions/users";
import { MenuItem, TextField } from "@material-ui/core";
import { addFaq } from "../../actions/admin.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  question: "",
  answer: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function AddFAQ() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [userdet, setUser] = useState();
  // const [result, setFormValue] = useState({});
  // const [categoryname, setCategoryname] = useState("");
  // const [categorytype, setCategorytype] = useState([]);

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});


  const onChange = (e) => {
    e.preventDefault();
    console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formData, "formData of forms");
    // setValidateError(formData)
  };

  const { question, answer } = formValue;

  useEffect(() => {

  }, []);



  const handleFormSubmit = async (e) => {

    e.preventDefault();

    let reqData = {
      question,
      answer,

    };
    console.log(reqData, "reqData");
    if (reqData.question === '') {
      toast.error('Please enter question field')
      return
    } else if (reqData.answer === '') {
      toast.error('Please enter answer field')
    } else {

      let error = await addFaq(reqData);

      console.log(error, "error+");
      if (isEmpty(error)) {
        toast.success("Faq details created");

        history.push('/faqlist')
        setValidateError(error);
      }
    }
  };
  const buttonHandler = () => {
    history.push('/faqlist')
  }

  return (

    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add FAQ</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                  <Button color={'primary'} onClick={() => { buttonHandler() }}>back</Button>


                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Question"
                      onChange={onChange}
                      id="question"
                      //   value={question}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Answer"
                      //   value={answer}
                      onChange={onChange}
                      id="answer"
                      name="answer"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />

                  </GridItem>

                </GridContainer>

              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Create
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}


