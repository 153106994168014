import React from "react"
import Web3 from "web3";
import { getCurAddr } from "./token";
import Network from "../lib/network";
import { toast } from "react-toastify";
import contractAbi from './contractAbi.json'
import { createMarket, createWhiteList, deleteWhiteList } from "../actions/admin";


const CreateWhiteList = async (contractaddress, WhitelistAddress, whitelistvalue,setButtonLoader, history) => {


    try {
        const details = await getCurAddr(Network)
        console.log(contractaddress, WhitelistAddress, whitelistvalue,'values');
        console.log(details, 'networkdeta');
        if (details == '' || details == null || details == undefined) {
            return setButtonLoader(false)
        } else {
            console.log('runner');
            if (window) {
                const { ethereum } = window
                const web3 = new Web3(ethereum || details.details.rpcUrls)
                const contract = new web3.eth.Contract(contractAbi, contractaddress)
                console.log(contract, 'ContractContract');
                const ownerAddress = await contract.methods.owner().call()
                console.log(ownerAddress);
                const address=[]
                const value=[]
                const invalidAddress=[]
                if (ownerAddress == details.address) {
                    WhitelistAddress.map(async (list) => {
                        console.log(list);
                       const isaddress= web3.utils.isAddress(list.address)
                       console.log(isaddress,list.address);
                       if(isaddress){
                           address.push(list.address)
                           value.push(list.value)

                       }else{
                        invalidAddress.push(list.address)
                       }
                    })
                    console.log(invalidAddress,'invalid');
                    if(invalidAddress.length>0){
                        setButtonLoader(false)

                        toast.error(`Please check the address`)
                        return 
                    }else{
                        console.log(address,value);
                        const Result = await contract.methods.updateWhitelistAddress(address,value).send({ from: details.address })
                        console.log(Result);
                        const payload = {
                            contractaddress: contractaddress,
                            whitelistaddress: whitelistvalue,
                            transactionhash: Result.transactionHash,
                            
                        }
                      const  result = await createWhiteList(payload)
                            setButtonLoader(false)
                            toast.success(result.message)
                            history.push('/collectionlist')
                    }
                    
                } else {
                    setButtonLoader(false)
                    toast.error('Your are not owner to update market')
                }
            }
        }
    } catch (error) {
        setButtonLoader(false)
        console.log(error);
    }
}

export default CreateWhiteList