import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { GetAllUser, GetAllUserwithoutwhitelist } from "../../actions/admin"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import { TextField, makeStyles } from "@material-ui/core"
import moment from "moment"
import DataTable from "react-data-table-component"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Button from "../../components/CustomButtons/Button.js";
import CreateWhiteList from "../../constant/createWhiteList";
import { object } from "prop-types";
import { toast } from "react-toastify";
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const AddWhiteList = () => {
    const classes = useStyles();
    const [data, setData] = useState([])

    const params = useParams()
    const [contractAddress, setContractAddress] = useState(params.id)
    const [whitelistaddr, setWhiteListAddr] = useState([])
    const [whitelistvalue, setWhitelistvalue] = useState([])
    const [buttonloader, setButtonLoader] = useState(false)
    let WhiteListArray = []
    const history = useHistory()
    useEffect(() => {
        // getLoader()
    }, [])

    // const getLoader = async () => {
    //     const result = await GetAllUserwithoutwhitelist(params.id)
    //     console.log(result, 'res');
    //     if (result) {
    //         setData(result)
    //     }
    // }

    // const changeHandler = async (e, address) => {
    //     console.log(e, address, 'eaddress');
    //     if (e) {
    //         setWhiteListAddr([...whitelistaddr, address])
    //         setWhitelistvalue([...whitelistvalue, 1])
    //     } else {
    //         console.log('hellow', whitelistaddr);
    //         const var1 = whitelistaddr.filter((e) => e !== address)
    //         const var2 = whitelistvalue.pop()
    //         console.log(var1);
    //         setWhiteListAddr(var1)

    //     }

    // }

    const handlewhitelistChange = async (e) => {
        const { name, value } = e.target
        console.log(name, value, 'namevalue');
        setWhitelistvalue((value))
        if(value==''){
            setWhiteListAddr([])
        }else{
            try {
                const val = JSON.parse(value)
                console.log(val);
                
                setWhiteListAddr(val)
    
            } catch (error) {
                console.log(error);
            }
        }

    }

    console.log(whitelistaddr);
    const submitHandler = async (e) => {
        e.preventDefault()
        setButtonLoader(true)

        if(whitelistvalue&&whitelistaddr.length>0){

            const result = await CreateWhiteList(contractAddress, whitelistaddr, whitelistvalue, setButtonLoader, history)
        }else{
            console.log(whitelistvalue);
            if(whitelistvalue == ''){
                setButtonLoader(false)

                toast.error('Enter the field')
            }else{
                setButtonLoader(false)
                toast.error('Invalid Format')
            }
        }

    }

    const buttonHandler=()=>{
        history.push('/collectionlist')
    }
    console.log(whitelistaddr, whitelistvalue, 'Whitelistaddress');
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card >

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Add White List</h4>
                        </CardHeader>
                        <CardBody>

                        <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                <Button color={'primary'} onClick={() => { buttonHandler() }}>back</Button>

                               
                            </GridItem>

                            <div>
                            
                                <TextField style={{ width: '400px', marginBottom: '10px' }} label={'Address format'} disabled value={'[{"address":"0x9c4....035B5B1C869606","value":1}]'}/>
                            </div>

                            <TextField
                                id="whitelistaddress"
                                label="Input WhiteList Address"
                                name='Address'
                                multiline
                                style={{ width: '700px', marginBottom: '10px' }}
                                onChange={(e) => handlewhitelistChange(e)}
                                helpewhitelistaddrrText=""
                            >

                            </TextField>

                            <GridItem>
                                <Button color="primary" disabled={buttonloader} onClick={(e) => submitHandler(e)}><i className={buttonloader ? "fa fa-spinner fa-spin" : ''} ></i>Create</Button>
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}


export default AddWhiteList