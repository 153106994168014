
import React, { useEffect, useRef, useState } from "react"
import GridContainer from "../../components/Grid/GridContainer"
import { withStyles } from '@material-ui/core/styles';
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import DataTable from "react-data-table-component"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography, makeStyles } from "@material-ui/core"
import { GetAllCollection, Search, SearchCollection } from "../../actions/admin"
import moment from "moment"
import CustomInput from "../../components/CustomInput/CustomInput"
import { Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import AddWhiteList from "../whitelist/addwhitelist"
import CreateWhiteList from "../../constant/createWhiteList";
import { roseBoxShadow } from "../../assets/jss/material-dashboard-react";
import { scrollIntoView } from "react-select/dist/index-ea9e225d.cjs.prod";

const styles1 = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

// const styles = (theme) => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(2),
//     },
//     closeButton: {
//         position: 'absolute',
//         right: theme.spacing(1),
//         top: theme.spacing(1),
//         color: theme.palette.grey[500],
//     },
// });

const useStyles = makeStyles(styles1);
const CollectionList = () => {
    const classes = useStyles();

    const [data, setdata] = useState([])
    const [user, setUser] = useState([])
    const history = useHistory()
    const [search, setSearch] = useState('')
    const [WhiteListAddr,setWhiteListAddr]=useState([])
    const [whitelistvalue,setWhitelistvalue]=useState([])
    const [buttonloader,setButtonLoader]=useState(false)
    const [ContractAddress,setContractAddress]=useState('')
    const [page,setPage]=useState(1)
    const scrollRef = useRef();
    // const tableContainer = tableRef.current.parentNode;

  // Attach an event listener when the page changes
 
    useEffect(() => {
        getLoader()

    }, [search])
    // const handlePageChange = async(pageNumber) => {
    //     console.log(pageNumber);
        
    //     setPage(pageNumber);
    //    await scrollToTop()
    //   };

    //   const scrollToTop = () => {
    //     const tableContainer = document.getElementById('tableContainer');
    //     console.log('trueeeeeeeeeeeee');
    //     if (tableContainer) {
    //       tableContainer.scrollTo(0, 0);
    //     }
    //   };

      const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0; // Scroll to the top of the table
    }
  }, [tableRef]);
    const getLoader = async () => {

        const result = await GetAllCollection(search)
        console.log(result, 'collectionuser');

        if (result.length) {
            result.map((ele,i)=>{
                ele.SSNo=i+1
            })
            setdata(result)

        }
    }
    console.log(data,'DataData');
    const converter = (addr) => {
        console.log(addr);
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }

    }

    // const DialogTitle = withStyles(styles)((props) => {
    //     const { children, classes, onClose, ...other } = props;
    //     return (
    //         <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //             <Typography variant="h6">{children}</Typography>
    //             {onClose ? (
    //                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
    //                     <CloseIcon />
    //                 </IconButton>
    //             ) : null}
    //         </MuiDialogTitle>
    //     );
    // });


    const buttonHandler = (id) => {
        console.log(id);
        history.push(`addmarket/${id}`)
    }

    // const submitHandler=async(e)=>{
    //     e.preventDefault()
    //     setButtonLoader(true)
    //     const array=[]
    //     console.log(WhiteListAddr.length);
    //     for(var i=0;i<WhiteListAddr.length;i++){
    //         console.log(whitelistvalue,'white');
    //         array.push(1)
    //     }
    //     console.log(array);
    //     // setWhitelistvalue(array)
    //     const result=await CreateWhiteList(ContractAddress,WhiteListAddr,array,setButtonLoader,history)
    // }
    console.log(whitelistvalue,WhiteListAddr,'wwwwwwwwwwwwwwwwwwwwwwwwwww');
    const addwhiteListHandler = async (contractAddress) => {
        console.log(contractAddress, 'WhiteListContract');
        history.push(`/addwhitelist/${contractAddress}`)

    }
    const removewhiteListHandler = async (contractAddress) => {
        console.log(contractAddress, 'WhiteListContract');
        history.push(`/removewhitelist/${contractAddress}`)
    }

    const marketlistHandler=async(contractAddress)=>{
        console.log(contractAddress)
        history.push(`/marketlist/${contractAddress}`)
    }

    const handlePageChange=(pagenumber)=>{
        if (scrollRef.current) {
            const tableContainer = scrollRef.current;
            const tableHeading = tableContainer.querySelector('.table-heading');
          
            console.log(tableHeading,'pageNumber');
            if (tableHeading) {
              console.log(pagenumber, 'pageNumber');
              
              // tableContainer.scrollTop = tableHeading.offsetTop;
              tableHeading.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }


    const handleRowPerPageChange=(limit)=>{
    
        if (scrollRef.current) {
          const tableContainer = scrollRef.current;
          const tableHeading = tableContainer.querySelector('.table-heading');
         
          console.log(tableHeading);
          if (tableHeading) {
            console.log(limit, 'pageNumber');
            
            // tableContainer.scrollTop = tableHeading.offsetTop;
            tableHeading.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    // const [open, setOpen] = useState(false);

    // const handleClickOpen = (address) => {
    //     setContractAddress(address)
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };
    const columns = [
        {
            name: "S.No.",
            // sortable: true,
            width: '80px',
            height: '350px',
            cell: (row, index) => <div data-tag="allowRowEvents">{row.SSNo}</div>,
        },
        {
            name: "Name",
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.name}</div>
                </div>
            ),
        },
        {
            name: "Logo",
            // sortable: true,
            cell: (row) => (
               <img src={`${row.logourl}`} style={{ width: '100px', height: '90px', padding: '10px 15px 15px 0px' }} />
             
            ),
        },
        {
            name: "Contract Address",
            // sortable: true,
            width:'200px',
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{converter(row.ContractAddress)}</div>
                </div>
            ),
        },
        {
            name: "Max Mint",
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.maxmintperaddress}</div>
                </div>
            ),
        }, {
            name: "Max Hold",
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.maxholdingperaddress}</div>
                </div>
            ),
        }, {
            name: "Mint Finished",
            // sortable: true,
            width: '130px',
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.mintfinished ? <span class="badge badge-pill badge-danger">MintFinished</span> : <span class="badge badge-pill badge-success">MintAvailable</span>}</div>
                </div>
            ),
        }, {
            name: "Mint Open",
            // sortable: true,
            cell: (row) => (
                <div>
                    <div data-tag="allowRowEvents">{row.mintopen ? <span class="badge badge-pill badge-success">Open</span> : <span class="badge badge-pill badge-danger">Closed</span>}</div>
                </div>
            ),
        },
        {
            name: "WhiteList",
            // sortable: true,
          
            cell: (row) => (
                <div>
                    {console.log(row.whitelist, '.whitelist')}
                    <div data-tag="allowRowEvents">{row.whitelist ? <div><span class="badge badge-pill badge-success">True</span>
               
                    </div> : <span class="badge badge-pill badge-danger">False</span>}</div>
                </div>
            ),
        },
        {
            name: "WhiteList Action",
            // sortable: true,
            width: '150px',
            cell: (row) => (
                <div>
                    {
                        row.whitelist?
                      
                    row.whitelistAddressStatus?
                    <Button onClick={() => removewhiteListHandler(row.ContractAddress)}><i class="fa fa-edit" ></i></Button>:
                        <Button   onClick={()=>addwhiteListHandler(row.ContractAddress)}><i class="fa fa-plus" ></i></Button>
                        :'---'
                }
                </div>
            ),
        },
        {
            name: "Market Action",
            width:'150px',
            // sortable: true,
          
            cell: (row) => (
                <div>
                    {
                        row.marketliststatus?
                        <Button onClick={() => marketlistHandler(row.ContractAddress)}><i class="fa fa-list" aria-hidden="true"></i></Button>:
                        <Button  onClick={() => buttonHandler(row.ContractAddress)}><i class="fa fa-plus" ></i></Button>

                    }
                </div>
            ),
        },

        {
            name: "Created At",
            // sortable: true,
            width:'200px',
            cell: (row) => (

                <div data-tag="allowRowEvents">  {moment(row.timestamp).format("MMMM,Do YYYY, hh:mm A")}</div>
            ),
        },

    ]

    const handleChange = async (e) => {
        const { name, value } = e.target;
        console.log(value);
        setSearch(value)
        // if (value.length >= 1) {
        //   const result = await SearchCollection(value);
        //   console.log(result.result.data.result);

        // setdata([...result.result.data.result])
        // }
    };

    // const handlewhitelistChange = (e) => {
    //     const { name, value } = e.target
    //     console.log(name, value, 'namevalue');
    //    const whitelistaddress= value.split(',')
    //    console.log(whitelistaddress,'wht');
    //     setWhiteListAddr( whitelistaddress)
    // }
    // console.log(WhiteListAddr,'whitelistAddress');
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid ">
                    <Card>

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Collection List</h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                <TextField type={'Search'} label={"Search"} variant={'outlined'} onChange={(e) => handleChange(e)} />
                            </GridItem>
                            <div ref={scrollRef} >
                                <div className="text_pad table-heading" >
                            {
                                data && data.length >0 ?
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        pagination={true}
                                        subHeaderComponent={
                                            <button onClick={() => tableRef.current && (tableRef.current.scrollTop = 0)}>Scroll to Top</button>
                                          }
                                          noDataComponent={<div>No data available.</div>}
                                          ref={tableRef}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowPerPageChange}
                                        // handlePageChange
                                        paginationPerPage="10"
                                        paginationRowsPerPageOptions={[2, 10, 15, 20]}
                                    /> : ''
                            }
                            </div>

</div>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/* <div>
                <GridContainer>
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Add White List Address
                        </DialogTitle>
                        <DialogContent dividers >
                            <GridItem xs={12} sm={12} lg={6}>

                                <TextField
                                    id="whitelistaddress"
                                    label="Input WhiteList Address"
                                    name='Address'
                                    multiline
                                    style={{ width: '400px' }}
                                    onChange={(e) => handlewhitelistChange(e)}
                                    helperText=""
                                >
                                   
                                </TextField>
                            </GridItem>

                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitHandler} color="primary">
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                </GridContainer>
            </div> */}
        </div>
    )
}

export default CollectionList