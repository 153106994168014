// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon

} from "@material-ui/icons";

import AdjustIcon from '@material-ui/icons/Adjust';

const dashboardRoutes = [
  {
    id: "login",
    key:'login',
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },

  // {
  //   id: "security",
  //   path: "/securityType",
  //   isSideMenu: false,
  //   isEdit: false,
  //   isRestricted: 'common'
  // },
  {
    id: "edit-profile",
    path: "/editprofile",
    key:'edit-profile',
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "change-password",
    path: "/changepassword",
    key:'change-password',
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    key:'dashboard',
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: 'single'
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        key:'dashboardlist',
        path: "/dashboard",
        icon: Person,
      },

    ]

  },

  

  /* Dashboard */
  {
    path: "#",
    id: "faq",
    name: "FAQ",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "FAQ Lists",
        path: "/faqlist",
        key:'faqlist',
        icon: Person,
      },

    ]
  },
    {
      path: "#",
      id: "mint",
      name: "Mint",
      icon: List,
      type: "multi",
      isEdit: false,
      isSideMenu: true,
      child: [
        {
          name: "Mint Lists",
          path: "/mintlist",
          key:'mintlist',
          icon: Person,
        },
  
      ]
    },
      {
        path: "#",
        id: "user",
        name: "Users",
        icon: List,
        type: "multi",
        isEdit: false,
        isSideMenu: true,
        child: [
          {
            name: "User Lists",
            path: "/userlist",
            key:'userlist',
            icon: Person,
          },
    
        ]

  },
  {
    path: "#",
    id: "collection",
    name: "Collection",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Collection Lists",
        path: "/collectionlist",
        key:'collectionlist',
        icon: Person,
      },{
        name:"Create Collection",
        path:'/createcollection',
        key:'creatcollection',
        icon: Person,
      }

    ]

},

];

export default dashboardRoutes;
