import React, { useState, useEffect } from "react";
// // @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle";
import Select from "react-select";

import "react-phone-input-2/lib/style.css";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

// import { addFaq, getadminUsers, getcategorytype } from "../../actions/users";
import  CircularProgress  from "@material-ui/core/CircularProgress";
import { addFaq, checkMarket, getContract } from "../../actions/admin.js";
import CreatMarket from "../../constant/createmarket.js";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Icon } from "@material-ui/core";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};

// toaster config
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const useStyles = makeStyles(styles);
const customStyle = makeStyles(customInputStyle);

export default function AddFAQ() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({ ContractAddress: '' })

    const [formValue, setFormValue] = useState({ marketname: '', websiteurl: '', marketcontractaddress: '', status: false });
    const [validateError, setValidateError] = useState();
    const [buttonloader, setButtonLoader] = useState(false)


    // const onChange = (e) => {
    //     e.preventDefault();
    //     console.log(e.target);
    //     const { id, value } = e.target;
    //     let formData = { ...formValue, ...{ [id]: value } };
    //     setFormValue(formData);
    //     console.log(formData, "formData of forms");
    //     // setValidateError(formData)
    // };

  
    const params = useParams()
    

    useEffect(() => {
        // getLoader()
    }, []);

    const getLoader = async () => {
        const result = await getContract(params.id)
        console.log(result, 'contract');
        if (result) {
            setData(result)
        }

    }


    const handleFormSubmit = async (e) => {

        e.preventDefault();
        setButtonLoader(true)
        const result=await checkMarket(params.id,formValue)
        console.log(result);
        if(result.status){

            await CreatMarket(params.id, formValue,setButtonLoader,history)
        }else{
            setButtonLoader(false)
            toast.error('Market already exists')
        }

    };
    const buttonHandler=()=>{
        history.push('/collectionlist')
    }

    return (

        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Add Market</h4>
                                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
                            </CardHeader>
                            <CardBody>
                            <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                <Button color={'primary'} onClick={() => { buttonHandler() }}>back</Button>

                               
                            </GridItem>
                                <GridContainer>
                                    

                                    
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Contract Address"
                                            onChange={(e) => setFormValue({ ...formValue, ContractAddress: e.target.value })}
                                            id="contractaddress"
                                            value={params.id}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={5}>
                                        <CustomInput
                                            labelText="Market Place"
                                            //   value={answer}
                                            onChange={(e) => setFormValue({ ...formValue, marketname: e.target.value })}
                                            id="marketplace"
                                            name="marketplace"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CustomInput
                                            labelText="Website Url"
                                            onChange={(e) => setFormValue({ ...formValue, websiteurl: e.target.value })}
                                            id="websiteurl"
                                            //   value={question}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CustomInput
                                            labelText="Market Contract Address"
                                            onChange={(e) => setFormValue({ ...formValue, marketcontractaddress: e.target.value })}
                                            id="marketcontract"
                                            //   value={question}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />

                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} >
                                        <div className="custom_btn">

                                            <BootstrapSwitchButton onlabel="Active" offlabel="InActive" width={100} onChange={(e) => setFormValue({ ...formValue, status: e })} onstyle="primary" offstyle="secondary" />
                                        </div>
                                    </GridItem>

                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridItem >

                                <Button color={'primary'} disabled={buttonloader}  type="submit"  >
                                  <i className={buttonloader?"fa fa-spinner fa-spin":''} ></i>  Create
                                </Button>
                                </GridItem>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}


