import React, { useEffect, useState } from "react"
import { GetAllMint } from "../../actions/admin"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import { TextField, makeStyles } from "@material-ui/core"

import DataTable from "react-data-table-component"
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const MintList = () => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    useEffect(() => {
        getLoader()
    }, [search])

    const getLoader = async () => {
        const result = await GetAllMint(search)
        console.log(result, 'res');
        if (result.length) {
            result.map((ele,i)=>{
                ele.SSNo=i+1
            })
            setData(result)
        }
    }
    console.log(data);
    const converter = (addr) => {
        console.log(addr);
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }

    }
    console.log(data, 'data');
    const columns = [
        {
            name: "S.No.",
            // sortable: true,
            width: '80px',
            height: '350px',
            cell: (row, index) => <div data-tag="allowRowEvents">{row.SSNo}</div>,
        },
        {
            name: "User Address",
            // sortable: true,
            width: '380px',
            cell: (row) => (
                <div>
              
                    <div data-tag="allowRowEvents">{row.currentAddress}</div>
                </div>
            ),
        },
        {
            name: "Contract Address",
            // sortable: true,

            cell: (row) => (

                <div data-tag="allowRowEvents"><a href={`https://sepolia.etherscan.io/address/${row.contractAddress}`} target="_blank">{converter(row.contractAddress)}</a></div>
            ),
        },
        {
            name: "Transaction Hash",
            // sortable: true,

            cell: (row) => (row.transactionHash?<div data-tag="allowRowEvents"><a href={`https://sepolia.etherscan.io/tx/${row.transactionHash}` } target={'_blank'} >{converter(row.transactionHash)}</a></div>:'--'),
        },
        

    ];
    const handleChange=(e)=>{
        const {name,value}=e.target
        setSearch(value)
    }
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card>

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Mint List</h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                <TextField type={'Search'} label={"Search"} variant={'outlined'} onChange={(e) => handleChange(e)} />
                            </GridItem>

                            {
                                data && data.length>0 ?
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        noHeader
                                        pagination={true}
                                        paginationPerPage="10"
                                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    /> : ''
                            }




                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default MintList