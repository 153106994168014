import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { GetAllUser, GetAllUserwhitelist, GetAllUserwithoutwhitelist } from "../../actions/admin"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import { MenuItem, TextField, makeStyles } from "@material-ui/core"
import moment from "moment"
import DataTable from "react-data-table-component"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Button from "../../components/CustomButtons/Button.js";
import CreateWhiteList from "../../constant/createWhiteList";
import { toast } from "react-toastify";
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const RemoveWhiteList = () => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const params = useParams()
    const [contractAddress, setContractAddress] = useState(params.id)
    const [dafultwhitelist, setdefaultwhitelist] = useState([])
    const [whitelistaddr, setWhiteListAddr] = useState([])
    const [whitelistvalue, setWhitelistvalue] = useState([])
    const [buttonloader, setButtonLoader] = useState(false)
    const [value, setValue] = useState([])

    let WhiteListArray = []
    const history = useHistory()
    useEffect(() => {
        getLoader()
    }, [whitelistaddr])

    const getLoader = async () => {
        const result = await GetAllUserwhitelist(params.id)
        if (result.length) {

            setData(result)
            result.map(async (list) => {
                const parseofdata = await JSON.parse(list.WhiteListAddress)
                console.log(parseofdata);
                setdefaultwhitelist(parseofdata)
            })
        }
    }
    console.log(dafultwhitelist, 'default');

    const handleChange = async (e) => {
        let { name, value } = e.target
        console.log(name, value, "lllllllll");
        setWhitelistvalue(value)
        console.log(typeof value);

        //    const array= JSON.parse(value)
        //    console.log(array);
    }

    const differencebetweenarray = async (arr1, arr2) => {
        return arr2.filter(item1 => {
            return !arr1.some(item2 => item2.address === item1.address);
        });
    }




    const samebetweenarray = async (arr1, arr2) => {
        return arr2.filter(item1 => {
            return arr1.some(item2 => {
                if (item2.address === item1.address) {
                    return item2.value === item1.value
                }
            });
        });
    }



    const differencebetweenarrayvalue = async (arr1, arr2) => {
        return arr2.filter(item1 => {
            return arr1.some(item2 => {
                if (item2.address === item1.address) {
                    return item2.value != item1.value
                }
            });
        });
    }
    const differencebetweenarrayvalue1 = async (arr1, arr2) => {

        const updates = Object.fromEntries(arr2.map(o => [o.address, o]))
        const result = arr1.map(o => updates[o.address] || o);
        
        return result

        // return arr2.filter(item1 => {
        //     return arr1.some(item2 => {
        //         if (item2.address != item1.address) {
        //             console.log(item1, 'item1');
        //             return item1
        //         }
        //     });
        // });
    }


    //     const differencebetweenarray=async(arr1,arr2)=>{
    //         console.log(arr1,arr2,'listofArray');
    //         let valuearray=[]
    //         let notvaluearray=[]
    //         const defaultaddress=[]
    //         const inputaddress=[]
    // arr1.map((list)=>{
    //     defaultaddress.push(list.address)
    // })
    // arr2.map((list)=>{
    //     inputaddress.push(list.address)
    // })
    // console.log(defaultaddress,inputaddress);


    //         arr1.map((list1)=>{
    //             console.log(list1,'listofArray');
    //             arr2.map((list2)=>{
    //                 console.log(list2,'listofArray');
    //                 if(list1.address===list2.address){
    //                     console.log(list2,'listofArray11');
    //                     if(list1.value != list2.value){
    //                         console.log(list2,'listofArray11');
    //                         valuearray.push(list2)
    //                         // valuearray.push(list2)
    //                     } 

    //                     // return valuearray
    //                 }else 

    //                 valuearray.push(list2)

    //             })
    //         })

    //         console.log(notvaluearray,'aaaaaaaaaaaaaaa');

    //         return valuearray

    //     }



    const submitHandler = async (e) => {
        e.preventDefault()



        const arr = []
        console.log(data.length, "surya123");
        if (whitelistvalue.length > 0) {
            try {
                for (var i = 0; i < data.length; i++) {
                    const dataofparsh = JSON.parse(whitelistvalue)
                    console.log(dataofparsh, "surya");
                    arr.push(dataofparsh)
                    setValue([...dataofparsh])
                    console.log(dataofparsh, 'lllll');
                    const arrayaddress = await differencebetweenarray(dafultwhitelist, dataofparsh)

                    const arrayvalue = await differencebetweenarrayvalue(dafultwhitelist, dataofparsh)
                    const arrayvalue1 = await differencebetweenarrayvalue1(dafultwhitelist, dataofparsh)
                    const samevalue = await samebetweenarray(dafultwhitelist, dataofparsh)

                    console.log(dafultwhitelist, arrayaddress, arrayvalue, arrayvalue1, samevalue, 'lllll')

                    // dataofparsh.map((list) => {

                    //     if (list.value == 0) {
                    //         console.log(list);
                    //         arrayvalue.push(list)
                    //     }
                    // })
                    const wholevalue = [...samevalue, ...arrayvalue]
                    console.log(wholevalue, 'wholestring');
                    const wholestring = JSON.stringify(wholevalue)
                    console.log(wholestring, 'wholestring');
                    console.log(whitelistvalue, 'arrayvalue');
                    setButtonLoader(true)
                    console.log(dataofparsh);

                    if (whitelistvalue && dataofparsh.length > 0) {
                        if (arrayvalue.length > 0 || arrayaddress.length > 0) {
                            if (wholevalue.length ==dafultwhitelist.length) {

                                const result = await CreateWhiteList(contractAddress, dataofparsh, wholestring, setButtonLoader, history)
                                console.log(result);
                            }else {
                                setButtonLoader(false)
                                console.log(whitelistvalue);
                                toast.error('Whitelist Address cannot be edited')
                            }

                        }
                        else {
                            setButtonLoader(false)
                            console.log(whitelistvalue);
                            toast.error('Edit the value to update whitelist')
                        }
                        //  const result = await CreateWhiteList(contractAddress, whitelistaddr, whitelistvalue, setButtonLoader, history)
                    } else {
                        if (whitelistvalue == '') {
                            console.log(whitelistvalue);
                            setButtonLoader(false)
                            toast.error('Address already updated')
                        } else {
                            setButtonLoader(false)
                            console.log(whitelistvalue);
                            toast.error('Whitelist Address cannot be edited')
                        }
                    }

                }
            } catch (error) {
                setButtonLoader(false)
                toast.error('Invalid Address format')
            }

        } else {
            setButtonLoader(false)
            toast.error('Edit the value to update whitelist')
        }

    }
    const buttonHandler = () => {
        history.push('/collectionlist')
    }


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card >

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Edit White List</h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem style={{ display: 'flex', justifyContent: "end" }}>
                                <Button color={'primary'} onClick={() => { buttonHandler() }}>back</Button>


                            </GridItem>

                            <div>
                                <lable disabled>
                                    Notes :
                                </lable>
                                <p>
                                You must be edit only value, If you edit address it will become error. 
                                </p>
                
                            </div>

                            <div style={{ display: "flex", gap: '20px' }}>

                                <div>

                                    {
                                        data.map((list) => {
                                            return (
                                                <TextField
                                                    id="whitelistaddress"
                                                    label="Input WhiteList Address"
                                                    name='Address'
                                                    multiline
                                                    // defaultValue={}
                                                    defaultValue={list.WhiteListAddress}
                                                    style={{ width: '700px', marginBottom: '10px' }}
                                                    onChange={(e) => handleChange(e)}
                                                    helperText=""
                                                >


                                                </TextField>
                                            )
                                        })
                                    }
                                </div>


                            </div>


                            <GridItem>
                                <Button color="primary" disabled={buttonloader} onClick={(e) => submitHandler(e)}><i className={buttonloader ? "fa fa-spinner fa-spin" : ''} ></i>Create</Button>
                            </GridItem>





                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}


export default RemoveWhiteList