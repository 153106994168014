import React, { useEffect, useRef, useState } from "react"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Button from "../../components/CustomButtons/Button.js";
import {  Checkbox, Grid, TextField, Typography, makeStyles } from "@material-ui/core"
import config from '../../lib/config'
import Web3 from "web3"
import { getCurAddr } from "../../constant/token"
import Network from '../../lib/network'
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleFormSubmit } from "../../constant/collectioncreate"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"


// import { useEthers, useEtherBalance } from "@usedapp/core";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);
const CreateCollection=()=>{


  // const { activateBrowserWallet, account } = useEthers();

    const classes = useStyles();
    const fileRef = useRef();
    // var selectednetwork = localStorage.getItem('network');
  const [selectednetwork, setSelectednetwork] = useState();
  const [NetworkValue,setNetworkValue]=useState('')
  
  const [UserAccountAddr, Set_UserAddress] = useState("");
  const [UserAccountBal, Set_UserAccountBal] = useState("0");
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState("");

  // const [contractAddress, setcontractAddress] = useState<any>(
  //   NetworkValue.singleContract ? NetworkValue.singleContract : {}
  // );
  const [photo, Set_photo] = useState("");
  const [userimage, Set_userimage] = useState("");
  const [owncollectionname, set_owncollectionname] = useState("");
  const [owncollectionsymbol, set_owncollectionsymbol] = useState("");
  const [collectiondescription, set_collectiondescription] = useState("");
  const [collectionurl, set_collectionurl] = useState(
    `${config.Front_URL}/collections-details/`
  );
  const [showloadercollect, Set_showloadercollect] = useState(false);
  const [WalletConnected, setWalletConnected] = useState(false);
  const [mainnet, setmainnet] = useState(false);
  const [ContractAddressUser, set_ContractAddressUser] = useState("");
  const [ownsinglecontract, setownsinglecontract] = useState("");
  const [customcollection, setcustomcollection] = useState("");

  const [collectionlist, set_collectionlist] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showcreate, setshowcreate] = useState(false);
  const [Window, setWindow] = useState({});
  const [buttonloader, setButtonLoader] = useState(false);
  const [open, setopen] = useState(false);
  const [data, setdata] = useState({
    name:'',
    totalsupply: 0,
    nftprice: 0,
    logourl: "",
    maxholdingperaddress: 0,
    discriptionurl: "",
    maxmintperaddress: 0,
    bannerurl: "",
    baseurl: "",
    mintopen:true,
    mintfinished:false,
    whitelist:false
  });
  //   const typeWindow = window as any;
  const { ethereum } = Window;
  const history=useHistory()
  useEffect(() => {
      const web3 = new Web3(Window.ethereum);
      setWindow(window);
      web3.eth.getChainId((err, chain) => {
          console.log(chain,'chhhhan');
          Network.find((o) => o.chainid === chain ? setNetworkValue(o) : "")
          loadInitialDatas();
    
          setSelectednetwork(chain);
    });
    
}, [Window,NetworkValue]);


async function loadInitialDatas() {
    await checkconnection();
}
const ToggleHandler = async () => {
    await setopen(true);
};
async function checkconnection() {
    //   console.log('runn');
    var prov_details = await getCurAddr(Network ,);
    console.log("rrr");

    console.log(prov_details, "prove");
    console.log(ethereum, "ether");

    if (ethereum != undefined) {
      const web3 = new Web3(prov_details ? ethereum : null);
      console.log(Window, Window.ethereum, "winnnnnn");

      if (
        prov_details &&
        prov_details != null &&
        prov_details != undefined &&
        prov_details != "" &&
        localStorage.getItem("address")
      ) {
        var network = await web3.eth.getChainId();
        if (NetworkValue.chainid === network) {
          setshowcreate(true);
        }
      }
    }
  }

  
  const CheckBoxHandler = (e) => {
    const { id, value } = e.target;
    setdata({ ...data, [id]: e.target.checked });
  };
  const changeHandler = (e) => {
    const { id, value } = e.target;
    // console.log(e.target.checked);

    if(id=='name'){
      const name=value
      if ( /[^A-Za-z" "\d]/.test(name)) {
       
        return (false);
    }else{
      setdata({ ...data, [id]: value });

    }
    }
    setdata({ ...data, [id]: value });


  };
  console.log(data);

  
  function convert(n) {  
		var sign = +n < 0 ? "-" : "",
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, "")
			.replace(/^([0-9]+)(e.*)/, "$1.$2")
			.split(/e|\./);
		return +pow < 0
			? sign +
			"0." +
			"0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
			lead +
			decimal
			: sign +
			lead +
			(+pow >= decimal.length
				? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
				: decimal.slice(0, +pow) + "." + decimal.slice(+pow));
	}
  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,9})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,9})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const handleFormSubmit1 = async (e) => {
    e.preventDefault();
    console.log('runnering');
    const nft=data.nftprice*1e18 
          console.log(nft,"surya1");
    var var1=nft.toString()
          console.log(var1,"surya2");

     var1= convert( var1)
              console.log(var1,"surya3");

    // console.log(var1,'nftprce');
    await handleFormSubmit({
      var1,
      data,
      setWalletConnected,
      setButtonLoader,
      Set_showloadercollect,
      owncollectionname,
      owncollectionsymbol,
      photo,
      collectionurl,
      setmainnet,
      set_ContractAddressUser,
      setownsinglecontract,
      collectiondescription,
      setcustomcollection,
      NetworkValue,
      set_collectionlist,
      history
    });
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
//   const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className="table_grid">
                    <Card>
                        
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Create Collection</h4>
                        </CardHeader>
                        <CardBody>                              
                                            
                        <form>
                <GridContainer
                  
                  style={{display:'flex',justifyContent:'space-between'}}
                >
                  <GridItem xs={12} sm={12} lg={6}>
                  <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      htmlFor="collection name"
                      // mb="5px"
                    >
                      Collection Name
                      <span className="text-muted">* </span>
                    </Typography>

                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={changeHandler}
                      id="name"
                      value={data && data.name}
                      name="Collectionname"
                      style={{ marginBottom: "15px" }}
                      // onBlur={handleBlur}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      htmlFor="collection name"
                      // mb="5px"
                    >
                      Total Supply
                      <span className="text-muted">* </span>
                    </Typography>

                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={changeHandler}
                      id="totalsupply"
                      // value={data && data.totalsupply}
                      name="Collectionname"
                      style={{ marginBottom: "15px" }}
                      onKeyDown={(evt) =>{
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()}
                      }
                      onInput={validPositive}
                      // onBlur={handleBlur}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Max Mint Per Address
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={changeHandler}
                      id="maxmintperaddress"
                      name="symbol"
                      // value={data.maxmintperaddress}
                      style={{ marginBottom: "15px" }}
                      onKeyDown={(evt) =>{
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()}
                      }
                      onInput={validPositive}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Max Holding Per Address
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={changeHandler}
                      style={{ marginBottom: "15px" }}
                      id="maxholdingperaddress"
                      // value={data.maxholdingperaddress}
                      onKeyDown={(evt) =>{
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()}
                      }
                      onInput={validPositive}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      
                      mb="5px"
                    >
                      NFT Price
                      <span className="text-muted">* </span>
                    </Typography>

                    <TextField
                      type="number"
                      variant="outlined"
                      onChange={changeHandler}
                      id="nftprice"
                      
                      // value={data.nftprice}
                      name="shortUrl"
                      // onBlur={handleBlur}
                      onKeyDown={(evt) =>{
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()}
                      }
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Base URL
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={changeHandler}
                      style={{ marginBottom: "15px" }}
                      id="baseurl"
                      value={data.baseUrl}
                      name="discription"
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Logo URL
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={changeHandler}
                      style={{ marginBottom: "15px" }}
                      id="logourl"
                      value={data.logourl}
                      name="discription"
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Description URL
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={changeHandler}
                      style={{ marginBottom: "15px" }}
                      id="discriptionurl"
                      value={data.discriptionurl}
                      name="discription"
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="label"
                      mb="5px"
                    >
                      Banner URL
                      <span className="text-muted"> * </span>
                    </Typography>

                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={changeHandler}
                      style={{ marginBottom: "15px" }}
                      id="bannerurl"
                      value={data.banner}
                      name="Description"
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} lg={5}>
                    <GridItem style={{display:'flex'}}>
                    
                        {/* <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          component="label"
                          mb="5px"
                        >
                          Mint Open
                          <span className="text-muted"> * </span>
                        </Typography>
                        <Checkbox
                          {...label}
                        //  disabled={true}
                        readOnly
                          // onChange={CheckBoxHandler}
                          id={"mintopen"}
                          value={data.mintopen}
                          checked={data.mintopen}
                        /> */}
                     
                  
                        {/* <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          component="label"
                          mb="5px"
                        >
                          MintFinished
                          <span className="text-muted"> * </span>
                        </Typography>
                        <Checkbox
                          {...label}
                          onChange={CheckBoxHandler}
                          id={"mintfinished"}
                          value={data.mintfinished}
                        /> */}
                     

                     
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          component="label"
                          mb="5px"
                        >
                          WhiteList
                          <span className="text-muted"> * </span>
                        </Typography>
                        <Checkbox
                          {...label}
                          onChange={CheckBoxHandler}
                          id={"whitelist"}
                          value={data.whitelist}
                          
                        />
                      
                    </GridItem>
                  </GridItem>
                </GridContainer>

                <GridItem style={{display:'flex',justifyContent:"end"}}>
                  <Button
                    type="submit"
                    color={"primary"}
                    variant="outlined"
                    // disabled={buttonloader}
                    disabled={buttonloader}
                    // style={{ marginTop: "15px" }}
                    // onClick={handleToggle}
                    onClick={handleFormSubmit1}
                  >
                      <i className={buttonloader?"fa fa-spinner fa-spin":''} ></i>  Create Collection
                  </Button>
                 
                </GridItem>
              </form>                                      
                     
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default CreateCollection