import react from 'react'
import axios from "axios";
import Web3 from "web3";
import config from '../lib/config'






export const getCurAddr1 = async (network, wallettype = "injected") => {


  // const Window = window;
  // const type = localStorage.getItem("wallet_type");
  // console.log("type", type);

  if (window.ethereum) {

    const web3 = new Web3(window.ethereum);
    console.log(window, "window cnnect");


    try {



      if (typeof web3 !== "undefined") {


        console.log(window.ethereum, "surys");


        if (window.ethereum && window.ethereum.isMetaMask) {


          const { ethereum } = window;

          console.log(ethereum, "wallettype");
          // if (!ethereum?.providers) {
          //   return undefined;
          // }

          let provider;
          switch (wallettype) {
            case 'walletlink12':
              provider = ethereum.c.find(
                ({ isCoinbaseWallet }) => isCoinbaseWallet
              );
              break;
            case 'injected':
              provider = ethereum.c.find(({ isMetaMask }) => isMetaMask);
              break;
            default:
              return;
          }


          console.log(provider, "provider");

          // await Window.ethereum.enable();
          const web3 = new Web3(provider);

          if (provider) {


            var network__id = await web3.eth.net.getId();
            console.log(network__id, "yes i am metamask", window.ethereum.networkVersion)



            const chainId = await web3.eth.getChainId()
            console.log(chainId);
            var details = await network.find((o) => { return (o.chainid == config.network ? o : "") });

            console.log(details, chainId, 'details');



            if (chainId == details.chainid) {

              console.log(details, 'details');
              var result = await web3.eth.getAccounts();
              var data = {
                address: result[0],
                details: details,
              };
              localStorage.setItem('network', details?.name)
              localStorage.setItem('address', result[0])
              console.log(data);

              return data;






            }
          }

          // else {

          //   var chainid = web3.utils.toHex(config.network);
          //   await web3.currentProvider
          //     .request({
          //       method: "wallet_switchEthereumChain",
          //       params: [{ chainId: chainid }],
          //     })
          //   // var details = network.find((o) => (o.chainid === chainId ? o : ""));
          //   localStorage.setItem('network', details?.name)
          //   console.log(details, 'details');

          //   var result = await web3.eth.getAccounts();
          //   var data = {

          //     address: result[0],
          //     details: details,
          //   };
          //   localStorage.setItem('network', details.name)
          //   localStorage.setItem('address', result[0])

          //   // window.location.reload()

          //   console.log(data);

          //   return data;

          // }


          // console.log('runn');





        } else {
          console.log('Reach here');

        }
      }
    } catch (err) {
      console.log(err);

    }
  }
};



export const getCurAddr = async (network, wallettype = "injected") => {
  if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    try {
      if (typeof web3 !== "undefined") {

        console.log(window.ethereum, "surya");

        if (window.ethereum && window.ethereum.providers && window.ethereum.providers?.length) {
          const { ethereum } = window;
          let provider;
          switch (wallettype) {
            case 'walletlink12':
              provider = ethereum.providers.find(
                ({ isCoinbaseWallet }) => isCoinbaseWallet
              );
              break;
            case 'injected':
              provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
              break;
            default:
              return;
          }
          if (provider) {
            ethereum.setSelectedProvider(provider);
          }
          web3 = new Web3(provider);
          if (provider) {

            const chainId = await web3.eth.net.getId();

            var details = await network.find((o) => { return (o.chainid == config.network ? o : "") });

            if (chainId == details.chainid) {

              console.log(details, 'details');
              var result = await web3.eth.requestAccounts();
              var data = {
                address: result[0],
                details: details,
              };
              localStorage.setItem('network', details?.name)
              localStorage.setItem('address', result[0])
              console.log(data);

              return data;
            }else{
         

              var chainid = web3.utils.toHex(config.network);
              await web3.currentProvider
                .request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: chainid }],
                })
              // var details = network.find((o) => (o.chainid === chainId ? o : ""));
              localStorage.setItem('network', details?.name)
              console.log(details, 'details');
  
              var result = await web3.eth.getAccounts();
              var data = {
  
                address: result[0],
                details: details,
              };
              localStorage.setItem('network', details.name)
              localStorage.setItem('address', result[0])
  
              // window.location.reload()
  
              console.log(data);
  
              return data;
        }
          }

        } else if (window.ethereum.isTrust) {

          alert("please Disable your trust wallet extension or reload page")

          // const data = activateBrowserWallet();
          // console.log(data, "datadata");

          // let enabled = await window.ethereum._events.disconnect();
          // if (enabled) {
          // var result = await web3.eth.requestAccounts();

          // } else {
          //   // const provider = await detectEthereumProvider()
          // web3 = new Web3(Web3.currentProvider);

          //   var details = await network.find((o) => { return (o.chainid == config.network ? o : "") });

          // var chainId = await web3.eth.net.getId();
          //   // var chainId = await web3.eth.net.getId();
          //   alert("trust da")
          // }
          // console.log(result);
          // const address = localStorage.getItem('network')
          // const details = localStorage.getItem('address')

          // var data = {
          //   address: address,
          //   details: details,
          // };
          // console.log(data);
          // return data;


        } else if (window.ethereum.isMetaMask) {
          var chainId = await web3.eth.net.getId();
          var details = await network.find((o) => { return (o.chainid == config.network ? o : "") });

          if (chainId == details.chainid) {

            console.log(details, 'details');
            var result = await web3.eth.requestAccounts();
            var data = {
              address: result[0],
              details: details,
            };
            localStorage.setItem('network', details?.name)
            localStorage.setItem('address', result[0])
            console.log(data);

            return data;
          }else{
         

                var chainid = web3.utils.toHex(config.network);
                await web3.currentProvider
                  .request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: chainid }],
                  })
                // var details = network.find((o) => (o.chainid === chainId ? o : ""));
                localStorage.setItem('network', details?.name)
                console.log(details, 'details');
    
                var result = await web3.eth.getAccounts();
                var data = {
    
                  address: result[0],
                  details: details,
                };
                localStorage.setItem('network', details.name)
                localStorage.setItem('address', result[0])
    
                // window.location.reload()
    
                console.log(data);
    
                return data;
          }

        } else {

          alert("add metamask extension")
        }

      }


    } catch (err) {

    }


  }

}







//   };
export const Collection_Create = async (data) => {
  console.log(data, 'data');

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/collection/create`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};


// check for individual collection name 
export const CkeckCollectionName = async (data) => {

  console.log(data);
  try {
    let res = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/collection/collectionname`,
      method: 'post',
      data: { data }
    })
    console.log(res.data);
    return res.data
  } catch (error) {
    console.log(error);
  }
}