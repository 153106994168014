import React from "react"
import Web3 from "web3";
import { getCurAddr } from "./token";
import Network from "../lib/network";
import { toast } from "react-toastify";
import contractAbi from './contractAbi.json'
import { createMarket } from "../actions/admin";
const CreatMarket = async (contractaddress, formValue,setButtonLoader,history) => {

    console.log(contractaddress, formValue);
    try {
        const details = await getCurAddr(Network)
        console.log(details, 'networkdeta');
        if (details == '' || details == null || details == undefined) {
            return setButtonLoader(false)
        } else {
            console.log('runner');
            if (contractaddress === '') {
                setButtonLoader(false)
                return toast.error('Enter the contract address')
            } else if (formValue.marketname == '') {
                setButtonLoader(false)
                return toast.error('Enter the market place name')
            } else if (formValue.websiteurl == '') {
                setButtonLoader(false)
                return toast.error('Enter the website url')
            } else if (formValue.marketcontractaddress == '') {
                setButtonLoader(false)
                return toast.error('Enter the market contract address')
            }else if(!formValue.status){
                setButtonLoader(false)
                return toast.error('Set active to approve market ')
            }
            if (window) {
                const { ethereum } = window
                const web3 = new Web3(ethereum || details.details.rpcUrls)
                const contract = new web3.eth.Contract(contractAbi, contractaddress)
                console.log(contract, 'ContractContract');
                const ownerAddress=await contract.methods.owner().call()
                console.log(ownerAddress);
                if(ownerAddress==details.address){

                    const Result = await contract.methods.setWhitelistAddress(formValue.marketcontractaddress, formValue.status).send({ from: details.address })
                    console.log(Result);
                    const payload = {
                        contractaddress: contractaddress,
                        marketplace: formValue.marketname,
                        websiteurl: formValue.websiteurl,
                        marketcontractaddress: formValue.marketcontractaddress,
                        status: formValue.status,
                        currentaddress: details.address,
                        transactionhash: Result.transactionHash
                    }
                    const result = await createMarket(payload)
                    setButtonLoader(false)
                    history.push('/collectionlist')
                    toast.success(result.message)
                }else {
                    setButtonLoader(false)
                    toast.error('Your are not owner to update market')
                }
            }
        }
    } catch (error) {
        setButtonLoader(false)
        console.log(error);
    }
}

export default CreatMarket